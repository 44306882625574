import { Component, OnInit } from '@angular/core';
import { Validators,FormBuilder,ReactiveFormsModule } from '@angular/forms';
import { SourceService } from '../source.service';
import {Router,ActivatedRoute} from "@angular/router";
@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.scss'],
})
export class BookAppointmentComponent implements OnInit {
  userForm;msg='';appointmentBooked=false;
  constructor(private formBuilder: FormBuilder,private source: SourceService,private router: Router) { 
    this.userForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      location: ['', Validators.required],
      disease: ['', Validators.required],
      date: ['', Validators.required],
      time: ['', Validators.required]
      });
  }

  bookAppointment(userData){
  console.log(userData);
  this.source.bookAppointment(userData)
  .subscribe(data => {
    console.log(data);
    this.msg=data[0].msg;
    console.log(data[0].success);
    if(data[0].success){
      this.appointmentBooked=true;
      this.userForm.reset();
      this.router.navigate(['/login/'+userData.mobile+'/'+data[0].appointmentId]);
    }
   
    
  });
 
}

  ngOnInit() {}

}
