import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute} from "@angular/router";
import { SourceService } from '../../source.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
page:any='';
  constructor(private route: ActivatedRoute,private source: SourceService,private sanitizer: DomSanitizer) { 
    let page=this.route.snapshot.paramMap.get("page");
    this.getPage(page);
  }

  getPage(page){
    this.source.getPage(page)
  .subscribe(data => {
    
    this.page = this.sanitizer.bypassSecurityTrustHtml(data); 
    
  });

  }

  ngOnInit() {}

}
