import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import SwiperCore, { Autoplay, Pagination,Navigation,Virtual } from 'swiper';

SwiperCore.use([Autoplay, Pagination,Navigation,Virtual]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HomeComponent implements OnInit {
  slides:any=1;notApp:any=true;
  constructor() { 
    
    if(window.innerWidth<=750){
      this.slides=1;
    }else{
      this.slides=3;
    }
  }

  ngOnInit() {}

}
