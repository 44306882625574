import { Component, OnInit } from '@angular/core';
import { Validators,FormBuilder,ReactiveFormsModule } from '@angular/forms';
import { SourceService } from '../source.service';
import {Router,ActivatedRoute} from "@angular/router";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm;otpForm;msg='';otp:any='';wrongOTP=0;mobile;apId;

  constructor(private formBuilder: FormBuilder,private source: SourceService,private router: Router,private route: ActivatedRoute) {

    
    let mobile=this.route.snapshot.paramMap.get("mobile");
    this.apId=this.route.snapshot.paramMap.get("apId");
    if(this.source.loginStatus()==mobile){
      this.router.navigate(['/account/my-appointments/'+this.apId]);
      }
   
    if((!this.source.loginStatus() || this.source.loginStatus()!=mobile) && mobile){
    let d={mobile:mobile};
    console.log(d);
      this.OTP(d);

    }
    this.loginForm = this.formBuilder.group({
      mobile: ['', Validators.required]
      });
      this.otpForm = this.formBuilder.group({
        otp: ['', Validators.required]
        });
   }

   OTP(mobileData){
    //let o=Math.floor((Math.random() * 1000) + 1);
    //let oo=Math.floor((Math.random() * 1000) + 1);
    //this.otp=o+''+oo;
    this.otp=Math.floor(100000 + Math.random() * 900000);
    this.mobile=mobileData.mobile;
    this.sendOTP(this.mobile);
    console.log(this.otp);
}

   sendOTP(mobileNo){
   
    this.source.sendOTP(this.mobile,this.otp)
  .subscribe(data => {});
}
resendOTP(){
  this.sendOTP(this.mobile);
}
verifyOTP(otp){
  
  if(otp.otp==this.otp){
     this.wrongOTP=0;
      this.login(this.mobile);
     }else{
         this.wrongOTP=1;
     }
  
}


login(mob){
  console.log(mob);
  this.source.getUser(mob)
.subscribe(data => {
 
  let d:any=data;
console.log(d);

if(!this.source.loginStatus()){
  localStorage.setItem("userId", d.mobile);
  localStorage.setItem("user", JSON.stringify(d));
  }
  

     if(this.apId){
      this.router.navigate(['/account/my-appointments/'+this.apId+'/huehuf6787798434ghgbjh34v']);
     }else{
      this.router.navigate(['/account']);
     }
     
      });
}

  ngOnInit() {}

}
