import { Component, OnInit } from '@angular/core';
import { SourceService } from '../../source.service';
import { Checkout } from 'capacitor-razorpay';
import { AlertController } from  '@ionic/angular';
import {Router,ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-my-appointments',
  templateUrl: './my-appointments.component.html',
  styleUrls: ['./my-appointments.component.scss'],
})
export class MyAppointmentsComponent implements OnInit {
  appointments:any=[];loading=0;isModalOpen=false;statuses;admin=false;apId;

  constructor(private source: SourceService,private alertController: AlertController,private route: ActivatedRoute) {
    this.apId=this.route.snapshot.paramMap.get("apId");
    let verify=this.route.snapshot.paramMap.get("verify");
    if(this.apId && verify){
      this.verifyAppointment();
    }
    console.log(this.apId);
   
  
      this.getAppointments();
    
    
   

   }

   getAppointments(){
    this.loading=1;
    var id=localStorage.getItem("userId");
    this.source.getAppointments(id,null,this.apId)
  .subscribe(data => {
    this.loading=0;
       console.log(data);
        this.appointments=data;
   });
   
}

verifyAppointment(){
 
  this.source.verifyAppointment(this.apId)
.subscribe(data => {
console.log(data);
 });
 
}

setOpen(isOpen: boolean,appointment) {
  this.isModalOpen = isOpen;
 
 this.source.getAppointmentStatus(appointment.id)
  .subscribe(data => {
    this.statuses=data;
       console.log(data);
        
   });
}


async getPay(appointment:any){
  const alert = await this.alertController.create({
    header: 'Enter amount to pay',
    buttons: [{
      text: 'Cancel',
      role: 'cancel',
      cssClass: 'secondary',
      handler: () => {
          console.log('Confirm Cancel');
      }
  }, 
  {
      text: 'Pay Now',
      handler: (alertData) => {
          console.log(alertData[0]);
          appointment.payment=alertData[0];
          this.getPayForward(appointment)
      }
  }],
    backdropDismiss: true,
    inputs: [
      {
        type: 'number',
        placeholder: 'Price',
        min: 1,
        max: 1000000,
      }
      
    ],
  });

  await alert.present();
}

getPayForward(appointment:any){
console.log(appointment);
this.payNow(appointment);
}

async payNow(appointment){
  const options = {
    key: 'rzp_test_6ZWz6itLG6lcNi',
    amount: String(appointment.payment*100),
    description: 'Your Trusted Oral Health Partner',
    image: 'https://dentaldoctor.biz/assets/logo.png',
    currency: 'INR',
    name: 'Dental Doctor',
    prefill: {
      email: '',
      contact: localStorage.getItem("userId")
    },
    theme: {
      color: '#4169E1'
    }
  };
  try {
    let data = (await Checkout.open(options));
    //console.log(data.response+"AcmeCorp");
    //let payment_id=data.response['razorpay_payment_id'];

    let d={
      "payment_id":data.response['razorpay_payment_id'],
      "appointment_id":appointment.id,
      "price":appointment.payment,
    }
    this.updatePayment(d);

    //this.presentAlert(data.response);
  } catch (error) {
    this.presentAlert('<ion-icon name="close-circle"></ion-icon> Error ! Payment not recieved.'); //Doesn't appear at all
  }
}

async presentAlert(response: string){
  // let responseObj = JSON.parse(response)
  //console.log("message"+ response['razorpay_payment_id']);
  const alert = await this.alertController.create({
    message:response,
    backdropDismiss: true,
  });

  await alert.present();
}

updatePayment(d:any){
  this.source.updatePayment(d)
  .subscribe(data => {
    this.presentAlert('<p class="bigIcon" ><ion-icon name="checkmark-circle"></ion-icon> <b>Payment Succesfull.</b></p>');
       this.appointments[0].payment_id=1;
        
   });
}

  ngOnInit() {}

}
