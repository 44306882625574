import { Component, OnInit } from '@angular/core';
import { Validators,FormBuilder,ReactiveFormsModule } from '@angular/forms';
import { SourceService } from '../source.service';
import {Router,ActivatedRoute} from "@angular/router";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm;otpForm;msg='';otp:any='';wrongOTP=0;mobile;apId;email:any;type:any='email';mobileEmail:any;

  constructor(private formBuilder: FormBuilder,private source: SourceService,private router: Router,private route: ActivatedRoute) {

    let mobile=this.route.snapshot.paramMap.get("mobile");
    this.apId=this.route.snapshot.paramMap.get("apId");

    if(this.source.loginStatus()==mobile){
      this.router.navigate(['/account/my-appointments/'+this.apId]);
      }
   
    if((!this.source.loginStatus() || this.source.loginStatus()!=mobile) && mobile){

    let d={mobileEmail:mobile};
      this.OTP(d);

    }
    this.loginForm = this.formBuilder.group({
      mobileEmail: ['', Validators.required]
      });
      this.otpForm = this.formBuilder.group({
        otp: ['', Validators.required]
        });
   }

   OTP(mobileData){
    //let o=Math.floor((Math.random() * 1000) + 1);
    //let oo=Math.floor((Math.random() * 1000) + 1);
    //this.otp=o+''+oo;
    this.otp=Math.floor(100000 + Math.random() * 900000);
console.log(mobileData);

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const mobileRegex = /^[0-9]{10}$/;
if(emailRegex.test(mobileData.mobileEmail)){
  this.type='mail';
}else if(mobileRegex.test(mobileData.mobileEmail)){
  this.type='mobile';
}else{
  alert('Wrong Input');
  return;
}


this.mobileEmail=mobileData.mobileEmail;
    
    console.log(this.mobileEmail);

    this.sendOTP();
    console.log(this.otp);
}

   sendOTP(){
    let oData={"OTP":this.otp,'mobileEmail':this.mobileEmail,'template':'otp'};
    this.source.sendOTP(oData,this.type)
  .subscribe(data => {});
  
}
resendOTP(){
  this.sendOTP();
}
verifyOTP(otp){
  
  if(otp.otp==this.otp){
     this.wrongOTP=0;
      this.login(this.mobileEmail);
     }else{
         this.wrongOTP=1;
     }
  
}


login(mobileEmail){
  console.log(mobileEmail);
  this.source.getUser(mobileEmail)
.subscribe(data => {
 
  let d:any=data;
console.log(d);

if(!this.source.loginStatus()){
  localStorage.setItem("userId", d.userId);
  localStorage.setItem("user", JSON.stringify(d));
  }
  

     if(this.apId){
      this.router.navigate(['/account/my-appointments/'+this.apId+'/huehuf6787798434ghgbjh34v']);
     }else{
      this.router.navigate(['/account/profile']);
     }
     
      });
}

  ngOnInit() {}

}
