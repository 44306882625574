import { Component, OnInit } from '@angular/core';
import { SourceService } from '../../source.service';
import { Validators,FormBuilder,ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
userId:any;  userForm;msg:any;
  constructor(private source: SourceService,private formBuilder: FormBuilder) { 
    this.userId=localStorage.getItem('userId');
    this.getUser(this.userId);

    this.userForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      userId: [localStorage.getItem('userId'), Validators.required]
      });

  }


  getUser(mob){
    
    this.source.getUser(mob)
  .subscribe(data => {
   let d:any=data;
   console.log(d);
     this.userForm.patchValue({name:d.name,email:d.email,mobile:d.mobile});
  

        });
  }

  userUpdate(data:any){
console.log(data);
  
this.source.userUpdate(data)
.subscribe(data => {
this.msg='Updated Succesfuly.';

      });
  }
  ngOnInit() {}

}
