import { Component } from '@angular/core';
import { SourceService } from './source.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  ifLogin:any;

  companyNav=false;
  constructor(private source: SourceService) {
    this.onActivate();
 
  }

  onActivate(){
    if(this.source.loginStatus()){
      this.ifLogin=this.source.loginStatus();
      }else{
        this.ifLogin=false;
      }
  }
}
