import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from "./home/home.component";

import { ServicesComponent } from "./pages/services/services.component";

import { BookAppointmentComponent } from "./book-appointment/book-appointment.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from './auth.guard';
const routes: Routes = [
  
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'book-appointment', component: BookAppointmentComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/:mobile/:apId', component: LoginComponent },
  
  {
    path: 'account',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),canActivate:[AuthGuard]
  },
  { path: ':page', component: ServicesComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabledBlocking' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
