import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
admin=false;
  constructor(private router: Router) { 
    let user= JSON.parse(localStorage.getItem("user"));
    console.log(user);
   
if(user.role=='admin'){
  this.admin=true;
  
}
  }
  logout(){
    localStorage.removeItem("userId");        
      localStorage.removeItem("user");
     
     this.router.navigate(['/']);
  }
  ngOnInit() {}

}
