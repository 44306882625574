import { Component, OnInit } from '@angular/core';
import { SourceService } from '../../source.service';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
})
export class PatientsComponent implements OnInit {
  patients:any=[];loading=0;isModalHistoryOpen=false;history:any=[];
  constructor(private source: SourceService) {
    this.getPatients();
   }


   getPatients(){
    this.loading=1;
    
    this.source.getPatients()
  .subscribe(data => {
    this.loading=0;
       console.log(data);
        this.patients=data;
   });
   
}

patientHistory(isOpen: boolean,patient:any) {
  this.isModalHistoryOpen = isOpen;

 this.source.getPatientHistory(patient.mobile)
  .subscribe(data => {
    this.history=data;
       console.log(data);
        
   });
}

  ngOnInit() {}

}
